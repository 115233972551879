type ObjectRecordValues = string | number | boolean | Array<string>;
type ObjectRecord = Record<string, ObjectRecordValues>;

const
    NUMBER_CODE_OFFSET: number = 48,
    UC_ALPHA_CODE_OFFSET: number = 65,
    LC_ALPHA_CODE_OFFSET: number = 97,
    STARTS_WITH_NUMBER_RE: RegExp = /^\d+\./;

let g_nSeqId: number = 0;

export const
    ID_DELIMITERS: string = ".",
    GET_CODE_FROM_ID_RE: RegExp = new RegExp("(^\\d+?)\\" + ID_DELIMITERS + "(.*)", "gi");

export const DELETE_PROPERTY_NAME = '_DELETE_';
export const UNMODIFIED_PROPERTY_NAME = '_UNMODIFIED_';

export class CommonUtils {
    /** 
     * This method generates a [RFC4122](https://www.ietf.org/rfc/rfc4122.txt) v4 UUID.
     * @example '9b1deb4d-3b7d-4bad-9bdd-2b0d7b3dcb6d'
     */
    static genGUID(): string {
        let uuid: string;

        let crypt = globalThis.crypto;
        if (!crypt || !crypt.randomUUID) {
            // @ts-ignore
            crypt = globalThis.nodeRequire('crypto');
        }

        if (!crypt || !crypt.randomUUID) {
            // to prevent crashes at runtime depending on the environment, ie: node / electron / etc..
            // @ts-ignore
            crypt = {
                randomUUID: () => `${Math.round(Math.random() * 100000000).toString()}-${Math.round(Math.random() * 10000).toString()}-${Math.round(Math.random() * 10000).toString()}-${Math.round(Math.random() * 10000)}-${Math.round(Math.random() * 100000000).toString()}`
            }
        }

        uuid = crypt.randomUUID();

        return uuid;
    }

    /**
     * This method generates a sequence number.
     */
    static genNextSequence(): number {
        return ++g_nSeqId;
    }

    /**
     * This method generates a random string.
     * @param size The size of the string to generate.
     * @example '9b1deb4d'
     */
    static genUUID(size: number = 8): string {
        let i: number,
            code: number,
            chunks: Array<string> = [];

        for (i = 0; i < size; i++) {
            code = Math.round(Math.random() * 59);

            if (code < 10) { // 0 - 9
                code = NUMBER_CODE_OFFSET + code;
            } else if (code < 34) { // A - Z
                code -= 9;
                code = UC_ALPHA_CODE_OFFSET + code;
            } else { // a - z
                code -= (9 + 25);
                code = LC_ALPHA_CODE_OFFSET + code;
            }

            chunks.push(String.fromCharCode(code));
        }

        return chunks.join('');
    }

    /**
     * Splits an entity ID into a catalog version ID and a code.
     * 
     * @param entityId - The entity ID to be split. It should be a string that may contain a dot ('.') but not a colon (':').
     * @returns A tuple where the first element is the catalog version ID (or `undefined` if not present) and the second element is the code.
     * @example splitEntityId("12345.abc") => ["12345", "abc"]
     */
    static splitEntityId(entityId: string): [string | undefined, string] {
        let catalogVersionId: string | undefined,
            code: string;

        if (!entityId) {
            return [undefined, ""];
        }

        if (entityId.includes(".") && !entityId.includes(":")) {
            let splitId: Array<string> = entityId.split(".");
            catalogVersionId = splitId.shift();
            code = splitId.join(".");
        } else {
            code = entityId;
        }

        return [catalogVersionId, code];
    }

    /**
     * Extracts a code from the given entity ID.
     *
     * @param entityId - The ID of the entity from which to extract the code.
     * @returns The extracted code as a string.
     * @example getCodeFromId("12345.abc") => "abc"
     */
    static getCodeFromId(entityId: string): string {
        GET_CODE_FROM_ID_RE.lastIndex = 0;
        return entityId?.replace(GET_CODE_FROM_ID_RE, '$2');
    }

    /**
     * Extracts the catalog version ID from the given entity ID.
     *
     * @param entityId - The entity ID from which to extract the catalog version ID.
     * @returns The extracted catalog version ID, or an empty string if the entity ID is invalid.
     * @example getCatalogVersionIdFromEntityId("12345.abc") => "12345"
     */
    static getCatalogVersionIdFromEntityId(entityId: string): string {
        GET_CODE_FROM_ID_RE.lastIndex = 0;
        return entityId?.replace(GET_CODE_FROM_ID_RE, '$1') || "";
    }

    /**
     * Resolves an ID using the provided entity ID and entity code.
     *
     * @param entityId - The ID of the entity.
     * @param entityCode - The code of the entity.
     * @returns The resolved ID.
     * @example getResolvedIdUsingEntityId("12345.abc", "xyz") => "12345.xyz"
     */
    static getResolvedIdUsingEntityId(entityId: string, entityCode: string): string {
        let catalogVersionId: string = this.getCatalogVersionIdFromEntityId(entityId);
        return this.getResolvedIdFromCode(catalogVersionId, entityCode);
    }

    /**
     * Resolves an ID from a given catalog ID and entity code.
     *
     * @param catalogId - The catalog ID, which can be a string or a number.
     * @param entityCode - The entity code as a string.
     * @returns The resolved ID as a string.
     * @example getResolvedIdFromCode("12345", "abc") => "12345.abc"
     */
    static getResolvedIdFromCode(catalogId: string | number, entityCode: string): string {
        let id: string;

        if (entityCode && STARTS_WITH_NUMBER_RE.test(entityCode)) {
            id = entityCode;
        } else {
            id = [catalogId, entityCode].join(ID_DELIMITERS);
        }

        return id;
    }

    /**
     * Checks if the given entity ID is valid.
     * 
     * An entity ID is considered valid if it can be split into a catalog version ID and a code,
     * where the catalog version ID is a number and the code is a non-empty string.
     * 
     * @param entityId - The entity ID to validate.
     * @returns `true` if the entity ID is valid, `false` otherwise.
     * @example isValidEntityId("12345.abc") => true
     * @example isValidEntityId("d:abc") => false
     */
    static isValidEntityId(entityId: string): boolean {
        const [catalogVersionId, code] = this.splitEntityId(entityId);
        return Boolean(catalogVersionId && !isNaN(parseInt(catalogVersionId)) && code && code.trim().length > 0);
    }

    /**
     * Flattens the given reference codes into a single object with key-value pairs.
     *
     * @param refCodes - An object containing various reference codes.
     * @returns An object where the keys are the reference code names and the values are the corresponding codes.
     *
     * @remarks
     * The function extracts the `ean`, `sku`, and `manufCode` properties from the `refCodes` object if they exist.
     * Additionally, it iterates over the `others` property (if present) and includes its key-value pairs in the result.
     *
     * @example
     * ```typescript
     * const refCodes: IReferenceCodes = {
     *   ean: "1234567890123",
     *   sku: "SKU12345",
     *   manufCode: "MANUF123",
     *   others: {
     *     customCode1: "CUSTOM1",
     *     customCode2: "CUSTOM2"
     *   }
     * };
     * 
     * const flatRefCodes = CommonUtils.getFlatRefCodes(refCodes);
     * console.log(flatRefCodes);
     * // Output:
     * // {
     * //   ean: "1234567890123",
     * //   sku: "SKU12345",
     * //   manufCode: "MANUF123",
     * //   customCode1: "CUSTOM1",
     * //   customCode2: "CUSTOM2"
     * // }
     * ```
     */
    static getFlatRefCodes(refCodes: IReferenceCodes | undefined): { [key: string]: string } {
        let flatRefCodes: { [key: string]: string } = {};

        if (refCodes) {
            if (refCodes.ean) {
                flatRefCodes["ean"] = refCodes.ean;
            }

            if (refCodes.sku) {
                flatRefCodes["sku"] = refCodes.sku;
            }

            if (refCodes.manufCode) {
                flatRefCodes["manufCode"] = refCodes.manufCode;
            }

            if (refCodes?.others) {
                for (let key in refCodes.others) {
                    flatRefCodes[key] = refCodes.others[key];
                }
            }
        }

        return flatRefCodes;
    }

    /**
     * Checks if all entries in the source object match the corresponding entries in the toMatch object.
     *
     * @param source - The source object to compare.
     * @param toMatch - The object to match against the source object. If not provided, the function returns false.
     * @returns `true` if all entries in the source object match the corresponding entries in the toMatch object, otherwise `false`.
     */
    static hasMatchingObjectEntries(source: ObjectRecord, toMatch?: ObjectRecord): boolean {
        let isValidMatch: boolean = false;

        if (toMatch) {
            isValidMatch = Object.entries(source)
                .every(([key, val]: [string, ObjectRecordValues]) => {
                    let loop: boolean = true;

                    if (toMatch[key] !== val) {
                        loop = false;
                    }
                    return loop;
                });
        }

        return isValidMatch;
    }

    /**
     * Checks if all elements in the `source` array are present in the `toMatch` array.
     *
     * @param source - The array of strings to check.
     * @param toMatch - The array of strings to match against. If not provided, the function returns `false`.
     * @returns `true` if all elements in `source` are found in `toMatch`, otherwise `false`.
     */
    static matchesAll(source: Array<string>, toMatch?: Array<string>): boolean {
        let isValidMatch: boolean = false;

        if (toMatch) {
            isValidMatch = source.every((tag: string) => toMatch.includes(tag));
        }

        return isValidMatch;
    }

    /**
     * Calculates the approximate memory size of an object in bytes.
     * 
     * This method traverses the properties of the given object and sums up the memory size
     * based on the type of each property. It handles circular references by keeping track
     * of objects that have already been processed.
     * 
     * @param parent_data - The object whose size is to be calculated.
     * @param size - The initial size to start with, typically 0.
     * @param objectRefs - An array to keep track of processed objects to avoid circular references.
     * @returns The total size of the object in bytes.
     */
    static sizeOf(parent_data: object, size: number, objectRefs: Array<object>): number {
        let prop: any;
        for (prop in parent_data) {
            let value: any = parent_data[prop as keyof object];
            if (prop === "catalog" || prop === "parentItem" || prop === "_catalogItem") {
                continue;
            }

            size += prop.length;

            if (typeof value === 'boolean') {
                size += 4;
            } else if (typeof value === 'string') {
                size += (value as string).length * 2;
            } else if (typeof value === 'number') {
                size += 8;
            } else {
                if (!objectRefs.includes(value)) { // prevent circular dependencies
                    objectRefs.push(value);
                    size += this.sizeOf(value, 0, objectRefs);
                }
            }
        }

        return size;
    }

    /**
     * Calculates the approximate memory size of an object in bytes.
     * @param object - The object whose size is to be calculated.
     * @returns The total size of the object in bytes.
     */
    static roughSizeOfObject(object: object): number {
        let size: number = 0;
        let prop: any;
        let objectRefs: Array<object> = [];

        for (prop in object) {
            size += prop.length;
            if (prop === "catalog" || prop === "parentItem") {
                objectRefs.push(object[prop as keyof object]);
            } else {
                size += this.sizeOf(object[prop as keyof object], 0, objectRefs);
            }
        }
        return size;
    }

    /**
     * Prunes the undefined properties from the given object.
     * @param object - The object to prune.
     * @returns The pruned object.
     */
    static pruneUndefined(object: Record<string, unknown | undefined>): Record<string, unknown> {
        Object
            .entries(object)
            .forEach(([key, val]: [string, unknown | undefined]) => {
                if (val === undefined) {
                    delete object[key];
                }
            });

        return object;
    }

    /**
     * Extracts the unique catalog IDs from the given item info.
     * @param itemId - The ID of the item.
     * @param configurationState - The configuration state of the item.
     * @returns A set of unique catalog IDs.
     */
    static extractUniqueCatalogVersionIdsFromItemInfo(itemId: string, configurationState: IConfigurationState | undefined): Set<string> {
        let itemCatalogVersionIds: Set<string> = new Set<string>(),
            itemCatalogVersionId: string = CommonUtils.getCatalogVersionIdFromEntityId(itemId);

        itemCatalogVersionIds.add(itemCatalogVersionId);

        configurationState?.forEach((featureState: IFeatureState) => {
            let featureCatalogVersionId: string = CommonUtils.getCatalogVersionIdFromEntityId(featureState.featureId),
                optionCatalogVersionId: string = CommonUtils.getCatalogVersionIdFromEntityId(featureState.optionId);

            itemCatalogVersionIds.add(featureCatalogVersionId);
            itemCatalogVersionIds.add(optionCatalogVersionId);
        });

        return itemCatalogVersionIds;
    }
}