
export class CatalogVersionInfoFactory {
    static create(info: V2.ICatalogVersionInfo): IConsolidatedCatalogVersionInfo {
        return new CatalogVersionInfo(info).serialize();
    }
}

export class CatalogVersionInfo {
    protected _source?: V2.ICatalogVersionInfoSource;
    protected _acquisition?: V2.ICatalogVersionInfoAcquisition;

    constructor(info: V2.ICatalogVersionInfo) {
        this._source = info.source;
        this._acquisition = info.acquisition;
    }

    serialize(): IConsolidatedCatalogVersionInfo {
        const info: IConsolidatedCatalogVersionInfo = {};

        if (this._source !== undefined) {
            info.source = this._source;
        }

        if (this._acquisition !== undefined) {
            info.acquisition = this._acquisition;
        }

        return info;
    }
}

export interface IConsolidatedCatalogVersionInfo {
    source?: IConsolidatedCatalogVersionInfoSource,
    acquisition?: IConsolidatedCatalogVersionInfoAcquisition,
}

export interface IConsolidatedCatalogVersionInfoSource {
    catalog?: IConsolidatedCatalogVersionInfoSourceCatalog;
}

export interface IConsolidatedCatalogVersionInfoSourceCatalog {
    name: string,
    format: string,
    version?: string,
    moreInfo?: Record<string, string>
}

export interface IConsolidatedCatalogVersionInfoAcquisition {
    applications: Record<string, IConsolidatedCatalogVersionInfoAcquisitionApplication>;
}

export interface IConsolidatedCatalogVersionInfoAcquisitionApplication {
    version?: string,
    moreInfo?: Record<string, string>,
    options?: Record<string, string>,
}