import { CatalogVersionInfo, IConsolidatedCatalogVersionInfo } from "./CatalogVersionInfo";
import { CatalogVersionBaseInfo, IConsolidatedCatalogVersionBaseInfo } from "./CatalogVersionBaseInfo";
import { PricingTaskList, IConsolidatedPricingTaskList } from "./PricingTaskList";

export class CatalogVersionFactory {
    static create(catalogVersion: ICatalogVersion | V2.ICatalogVersion): IConsolidatedCatalogVersion {
        return new CatalogVersion(catalogVersion).serialize();
    }
}

export class CatalogVersion extends CatalogVersionBaseInfo {
    protected _parentCatalogId: string;
    protected _createdDate: string;
    protected _updatedDate: string;
    protected _availableOffline?: boolean;
    protected _pricing?: PricingTaskList;
    protected _rating?: number;
    protected _certification?: ICatalogCertification;
    protected _info?: CatalogVersionInfo;

    constructor(catalogVersion: ICatalogVersion | V2.ICatalogVersion) {
        super(catalogVersion);

        this._parentCatalogId = catalogVersion.parentCatalogId;
        this._createdDate = catalogVersion.createdDate;
        this._updatedDate = catalogVersion.updatedDate;
        this._availableOffline = catalogVersion.availableOffline;
        this._rating = catalogVersion.rating;
        this._certification = catalogVersion.certification;

        let v2CatalogVersion: V2.ICatalogVersion = catalogVersion as V2.ICatalogVersion;

        if (v2CatalogVersion.pricing) {
            this._pricing = new PricingTaskList(v2CatalogVersion.pricing);
        }

        if (v2CatalogVersion.info) {
            this._info = new CatalogVersionInfo(v2CatalogVersion.info);
        }
    }

    serialize(): IConsolidatedCatalogVersion {
        const catalogVersion: IConsolidatedCatalogVersion = {
            ...super.serialize(),
            parentCatalogId: this._parentCatalogId,
            createdDate: this._createdDate,
            updatedDate: this._updatedDate
        };

        if (this._availableOffline !== undefined) {
            catalogVersion.availableOffline = this._availableOffline;
        }

        if (this._pricing !== undefined) {
            catalogVersion.pricing = this._pricing.serialize();
        }
        
        if (this._rating !== undefined) {
            catalogVersion.rating = this._rating;
        }
        
        if (this._certification !== undefined) {
            catalogVersion.certification = this._certification;
        }

        if (this._info !== undefined) {
            catalogVersion.info = this._info.serialize();
        }

        return catalogVersion;
    }
}

export interface IConsolidatedCatalogVersion extends IConsolidatedCatalogVersionBaseInfo {
    parentCatalogId: string;
    createdDate: string;
    updatedDate: string;
    availableOffline?: boolean;
    pricing?: IConsolidatedPricingTaskList;
    rating?: number;
    certification?: ICatalogCertification;
    info?: IConsolidatedCatalogVersionInfo;
}