export default {
    "displayCompany": 'sort',
    "displayPricing": false,      
    "displayAlternatives": false,
    "showMaterialCatalog": false,
    "showOnlyActivated": false,
    "displayCatalogFiltering": true, 
    "selectedCatalogType": 'all',    
    "hideNews": false,
    "theme": "orange",
    "itemView": "gallery",
    "thumbnailSize": 90,
    "favorite": [],
    "hiddenCatalogs": [],
    "selectedCatalogs": [],
    "selectedGroup": {},
    "selectedTab": '',
    "previousSearch": [],
    "openedGroup": false,
    "baseUrl": '',
    
    "conf_optionView": "gallery",
    "conf_unionOption": "union",
    "conf_thumbnailSize": 90,
    "conf_baseUrl": "",
    "locale": "en-us",

    "hideLightTab": true,    
    "enable_contextualHelp": true,
    "loaderColor": '#6038E8'
}
